import React from "react";
import PriceCalculationButton from "../components/PriceCalculator/PriceCalculationButton";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export default () => {
  return (
    <StaticQuery
        query={graphql`
        query BookingQuery {
            site {
                siteMetadata {
                  title
                  image
                  image768
                  image1024
                  image1216
                  image1408
                }
            }
      }`}
      render={() => {
        return (
        <div>
            <Helmet>
                <html className='has-navbar-fixed-top' />
                <title>Jetzt gratis Sicherheits-Beratungstermin buchen | MeinAlarm24</title>
                <meta name="description" content="-10% auf Alarmanlagen vom Testsieger 2019. MeinAlarm24 ist Ihr Fachpartner für Sicherheit ✓ Kostenlose Beratung ✓ Zertifizierte Techniker ✓ Vor Ort in Ihrer Stadt" />
                <meta name="keywords" content="" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content='Jetzt gratis Sicherheits-Beratungstermin buchen | MeinAlarm24' />
                <meta property="og:description" content="-10% auf Alarmanlagen vom Testsieger 2019. MeinAlarm24 ist Ihr Fachpartner für Sicherheit ✓ Kostenlose Beratung ✓ Zertifizierte Techniker ✓ Vor Ort in Ihrer Stadt" />
            </Helmet>
            <section>
                <iframe
                    title="beratung"
                    src='https://outlook.office365.com/owa/calendar/MeinAlarm24@meinalarm24.de/bookings/'
                    width='100%'
                    height='100%'
                    scrolling='none'
                    style={{border:0, position: "absolute", height: "100%", top: "95px"}}></iframe>
            </section>
        </div>
        )}
    }
    />
  );
};
